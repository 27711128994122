import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { getAuthToken, getAuthUser } from '../auth/auth';
import { UserRole, userRoleFromJSON } from '@ivy/proto/dist/users/v2/auth';

type AuthContextApi = {
  fetchAndSetAuthUser: (forceRefresh?: boolean) => Promise<void>;
  user: UserInfo | null | undefined;
};

export const AuthContext = React.createContext<AuthContextApi>({
  fetchAndSetAuthUser: async () => {},
  user: undefined,
});

export interface UserInfo {
  company: string;
  email?: string;
  id: string;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  role: UserRole;
  token: string;
}

const AuthProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null | undefined>();

  const fetchAndSetAuthUser = useCallback(async (forceRefresh?: boolean) => {
    try {
      const token = await getAuthToken(forceRefresh);
      const userResponse = await getAuthUser(token);
      const user = userResponse.user;
      if (!user) return;
      const role = userRoleFromJSON(user.role);
      setUserInfo({
        company: user.companyId,
        email: user.email,
        id: user.id,
        isAdmin: role === UserRole.USER_ROLE_ADMIN,
        isSuperAdmin: role === UserRole.USER_ROLE_SUPER_ADMIN,
        role,
        token: token,
      });
    } catch (e) {
      console.error(e);
      setUserInfo(null);
    }
  }, []);

  useEffect(() => {
    if (userInfo) return;
    fetchAndSetAuthUser();
  }, [fetchAndSetAuthUser, userInfo]);

  return (
    <AuthContext.Provider
      value={{
        fetchAndSetAuthUser,
        user: userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
export { AuthProvider };
