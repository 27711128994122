import { DeviceTelemetryAggregate, DeviceTelemetryType } from '@ivy/proto/dist/devices/v2/telemetry';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StorageUnitDetailContext from '../../../../../context/StorageUnitDetailContext';
import { getDeviceTelemetry, getLatestDeviceTelemetry } from '../../../../../gateway/deviceGateway';
import { colors } from '../../../../../theming/colors';
import TelemetryChart, { TelemetryData } from '../TelemetryChart';
import DataInfoCell from '../DataInfoCell';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { decodeTelemetry } from '../charts';
import { DeviceType } from '@ivy/proto/dist/devices/v2/device';
import AuthContext from '../../../../../context/AuthContext';
import UnassignDeviceButton from '../../../../../components/UnassignDeviceButton';

interface Props {
  type: DeviceTelemetryType;
}

const getUnitFromTelemetryType = (type: DeviceTelemetryType) => {
  switch (type) {
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TEMPERATURE:
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE:
      return '°C';
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_HUMIDITY:
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY:
      return '%';
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY:
      return 'kWh';
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_SWITCH_STATE:
      return 'Power'; // include this?
    default:
      return '';
  }
};

const getTitleFromTelemetryType = (type: DeviceTelemetryType) => {
  switch (type) {
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TEMPERATURE:
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE:
      return 'Temperature';
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_HUMIDITY:
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY:
      return 'Humidity';
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY:
      return 'Energy';
    case DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_SWITCH_STATE:
      return 'Unit power (on/off)';
    default:
      return '';
  }
};

const SensorDataDialog: React.FunctionComponent<Props> = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = React.useContext(AuthContext).user!;
  const params = useParams();
  const { selectedDevice } = React.useContext(StorageUnitDetailContext);
  const { toaster } = useToaster();

  const [loading, setLoading] = useState(true);
  const [telemetryData, setTelemetryData] = useState<{
    minSeries: TelemetryData[]; // TODO: This is a parsed version of the proto DeviceTelemetry type to prevent needing to convert to number lots of times
    maxSeries: TelemetryData[];
    latest: TelemetryData | undefined;
  }>({
    minSeries: [],
    maxSeries: [],
    latest: undefined,
  });

  const title = getTitleFromTelemetryType(type);
  const unit = getUnitFromTelemetryType(type);

  const onClose = useCallback(() => {
    //remove device/id
    const parts = location.pathname.split('/');
    const prevPath = parts.slice(0, parts.length - 2).join('/');
    navigate(prevPath, {
      replace: true,
    });
  }, [location.pathname, navigate]);

  const telemetryEndDate = useMemo(() => new Date(), []);

  let telemetryType = type;

  if (selectedDevice?.type === DeviceType.DEVICE_TYPE_LEGACY_SENSOR) {
    telemetryType =
      type === DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TEMPERATURE
        ? DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_TEMPERATURE
        : DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_LEGACY_HUMIDITY;
  }

  const fetchTelemetry = React.useCallback(async () => {
    setLoading(true);
    try {
      if (selectedDevice === undefined) return;

      // Fetch the data for the last 30 days
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);

      const telemetryQuery = {
        types: [telemetryType],
        startTime: startDate.toISOString(),
        endTime: telemetryEndDate.toISOString(),
      };

      const minTelemetryQuery = await getDeviceTelemetry(selectedDevice.deviceId, {
        ...telemetryQuery,
        aggregate: DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MIN,
      });

      const maxTelemetryQuery = await getDeviceTelemetry(selectedDevice.deviceId, {
        ...telemetryQuery,
        aggregate: DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MAX,
      });

      const latestTelemetryQuery = await getLatestDeviceTelemetry(selectedDevice.deviceId, {
        types: [telemetryType],
      });

      const [min, max, latest] = await Promise.all([minTelemetryQuery, maxTelemetryQuery, latestTelemetryQuery]);

      // We're only querying 1 telemetry type, so the array will only have 1 element
      setTelemetryData({
        minSeries: min[0]?.telemetry.map(decodeTelemetry) || [],
        maxSeries: max[0]?.telemetry.map(decodeTelemetry) || [],
        latest: latest[0]?.telemetry ? decodeTelemetry(latest[0].telemetry) : undefined,
      });
      setLoading(false);
    } catch (e) {
      console.error('Error fetching telemetry', e);
      toaster('Sorry!', 'There is something wrong, unable to load telemetry.', ToastMessageTypes.ERROR);
    }
  }, [selectedDevice, telemetryType, telemetryEndDate, toaster]);

  useEffect(() => {
    fetchTelemetry();
  }, [fetchTelemetry]);

  // Get the overall min and max values for all series
  const overallMaxTelemetryValue = telemetryData.maxSeries.reduce(
    (max, curr) => (max.value > curr.value ? max : curr),
    {
      timestamp: new Date(),
      value: 0,
    },
  );
  const overallMinTelemetryValue = telemetryData.minSeries.reduce(
    (min, curr) => (min.value < curr.value ? min : curr),
    {
      timestamp: new Date(),
      value: Number.MAX_VALUE,
    },
  );

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogContent>
        <Typography variant="h2">{title}</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container>
            <Grid item xs={4}>
              {telemetryData.latest ? (
                <DataInfoCell
                  label={`Latest: ${telemetryData.latest.timestamp.toLocaleDateString()}`}
                  value={`${telemetryData.latest.value.toFixed(1)}`}
                  unit={unit}
                />
              ) : (
                <p>Latest data could not be found</p>
              )}
            </Grid>
            {overallMaxTelemetryValue.value > 0 && (
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DataInfoCell
                  label={`Max ${overallMaxTelemetryValue.timestamp.toLocaleDateString([], {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                  })}`}
                  value={`${overallMaxTelemetryValue.value.toFixed(1)}`}
                  unit={unit}
                  color={colors.secondary}
                />
              </Grid>
            )}
            {overallMinTelemetryValue.value < Number.MAX_VALUE && (
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DataInfoCell
                  label={`Min ${overallMinTelemetryValue.timestamp.toLocaleDateString([], {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                  })}`}
                  value={`${overallMinTelemetryValue.value.toFixed(1)}`}
                  unit={unit}
                  color={colors.primary}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Box m={4} />

        <TelemetryChart
          title={`${title} (${unit})`}
          datasets={
            loading
              ? []
              : [
                  { data: telemetryData.minSeries, options: { label: 'Min', color: colors.primary } }, // Blue
                  { data: telemetryData.maxSeries, options: { label: 'Max', color: colors.secondary } }, // Red
                  // { data: telemetryData.averageSeries, options: { label: 'Avg', color: '#000' } },
                ]
          }
        />
      </DialogContent>

      <DialogActions style={{ padding: 24 }}>
        <div style={{ flex: 1 }} />
        {user.isSuperAdmin && (
          <UnassignDeviceButton
            deviceId={params.deviceId!}
            storageUnitId={params.storageUnitId!}
            setParentLoadingState={setLoading}
            onComplete={onClose}
          />
        )}
        <Button variant="contained" size="medium" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SensorDataDialog;
