import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';

import { TrashSimple } from 'phosphor-react';
import AuthContext from '../../context/AuthContext';
import React, { Dispatch, useContext, useState } from 'react';
import FormikSelect from '../../components/FormikSelect';
import FormikTextField from '../../components/FormikTextField';
import { countries, countryNames, Park, StorageUnit, StorageUnitAttribute, StorageUnitSizeCategory } from '../../model';
import { usePapaParse } from 'react-papaparse';
import FormikSwitch from '../../components/FormikSwitch';
import CompanyContext from '../../context/CompanyContext';
import { getCoordinates } from '../../gateway/geocodeGateway';
import StorageUnitForm from './components/StorageUnitForm';
import _ from 'lodash';
import { isAxiosError } from 'axios';
import { checkPermissionsForResource } from '@ivy/auth';

interface Props {
  park?: Park;
  onCancel: () => void;
  onSave: (values: Park) => Promise<void>;
  isEditForm?: boolean;
}

interface CsvStorageUnitRow {
  visualId: string;
  marketingLabel: string;
  categoryName: string;
  widthMeters?: string;
  depthMeters?: string;
  heightMeters?: string;
  areaSqm: string;
  description: string;
  high: string;
  garage: string;
  mezzanine: string;
  electricity: string;
  insulated: string;
}

const emptyPark = (): Park => {
  return {
    id: '',
    visualId: '',
    underConstruction: true,
    name: '',
    address: {
      line1: '',
      city: '',
      postalCode: '',
      state: '',
      countryCode: '',
    },
    manager: {
      name: '',
      contactChannels: [
        {
          type: '',
          value: '',
        },
      ],
    },
    storageUnits: [],
    unitIdCount: 0,
    rentals: [],
    iotRegistry: '',
    draft: true,
  };
};

let hideUnitDeleteIcon: boolean | undefined;

const ParkForm: React.FC<Props> = ({ park, onCancel, onSave, isEditForm }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [topError, setTopError] = React.useState<string | null>(null);
  const inputFile = React.useRef<HTMLInputElement | null>(null);
  const [unitState, setUnitState] = useState<StorageUnit[] | undefined>(park?.storageUnits);
  const company = useContext(CompanyContext);
  const auth = React.useContext(AuthContext);
  const { readString } = usePapaParse();

  hideUnitDeleteIcon = !checkPermissionsForResource(auth.user?.token!, 'storageUnit', 'delete') || isEditForm;

  const selectCsv = () => {
    inputFile?.current?.click();
  };

  const lookupOrCreateCategory = (name: string): StorageUnitSizeCategory =>
    company?.sizeCategories?.find(category => category.name === name) ?? {
      id: '',
      name: '',
      description: '',
      companyId: company?.id ?? '',
    };

  const lookupAttributes = (row: CsvStorageUnitRow): StorageUnitAttribute[] =>
    company?.attributes?.filter(attribute => row[attribute.name]?.toLocaleLowerCase() === 'true') ?? [];

  const readCsv = (e: React.ChangeEvent<HTMLInputElement>, formik: any) => {
    console.log(`parsing ${e.target.files![0].name}`);
    setLoading(true);
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async e => {
      const csvString = (e.target as any).result;
      readString(csvString, {
        header: true,
        worker: true,
        skipEmptyLines: 'greedy',
        complete: ({ data, errors }) => {
          errors.map(error => console.error(error));
          const newUnits = (data as CsvStorageUnitRow[]).map(row => {
            return {
              id: '',
              visualId: row.visualId,
              marketingLabel: row.marketingLabel,
              category: lookupOrCreateCategory(row.categoryName),
              description: row.description,
              areaSqm: +row.areaSqm,
              depthMeters: row.depthMeters ? +row.depthMeters : 0,
              heightMeters: row.heightMeters ? +row.heightMeters : 0,
              widthMeters: row.widthMeters ? +row.widthMeters : 0,
              keys: [],
              attributes: lookupAttributes(row),
              parkId: park?.id,
              activeDeviceRatio: 0,
            } as StorageUnit;
          });

          setUnitState([...formik.values.storageUnits, ...newUnits]);
          setLoading(false);
        },
      });
    };
    reader.readAsText(e.target.files![0]);
  };

  const initialValues = park || emptyPark();
  const onSubmit = async (values: Park) => {
    setLoading(true);
    unitState && (values.storageUnits = unitState);
    try {
      await onSave(values);
    } catch (e) {
      if (isAxiosError(e) && (e.response?.data.message || e.response?.data)) {
        setError(e.response?.data.message ?? e.response?.data);
      } else {
        setError('Da ist etwas schiefgegangen');
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<Park>({
    initialValues,
    onSubmit,
  });

  if (!error && !auth.user!.company) {
    setError('Cannot create a Park without a Company Context.');
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <FormikProvider value={formik}>
        <Dialog
          open={true}
          sx={{
            '& .MuiPaper-root': { width: '100%', maxWidth: 1200 },
            '& .MuiDialogActions-root': { padding: 2 },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Add new Park</DialogTitle>
            <Alert severity="info">Changes may take up to 1 hour to take effect</Alert>
            {topError != null && (
              <>
                <Box m={4} />
                <Alert severity="error" onClose={() => setTopError(null)}>
                  {topError}
                </Alert>
              </>
            )}
            <DialogContent>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <ParkData formik={formik} setTopError={setTopError} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Manager />
                </Grid>
              </Grid>{' '}
              <Box m={4} />
              <Units unitState={unitState} setUnitState={setUnitState} />
              {error != null && (
                <>
                  <Box m={4} />
                  <Alert severity="error" onClose={() => setError(null)}>
                    {error}
                  </Alert>
                </>
              )}
            </DialogContent>

            <Divider />
            <DialogActions>
              {checkPermissionsForResource(auth.user?.token!, 'storageUnit', 'create') && (
                <>
                  <Button variant="outlined" size="medium" onClick={selectCsv}>
                    Import CSV
                  </Button>
                  <input
                    type="file"
                    accept=".csv"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={e => readCsv(e, formik)}
                  />
                </>
              )}

              <div style={{ flex: 1 }} />

              <Button variant="outlined" size="medium" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="contained" size="medium" type="submit">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </FormikProvider>
    </>
  );
};

const ParkData: React.FC<{ formik: any; setTopError: any }> = props => {
  const auth = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);

  const geocodeAddress = async () => {
    setLoading(true);
    props.setTopError(null);
    try {
      const address = props.formik.values.address;
      const geolocated = await getCoordinates(address);
      if (geolocated) {
        props.formik.setFieldValue('address.location.lat', geolocated.latitude);
        props.formik.setFieldValue('address.location.long', geolocated.longitude);
      }
      setLoading(false);
    } catch (error: any) {
      props.setTopError(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>Info & Address</Typography>
        </Grid>
        <Grid item xs={8} md={9}>
          <FormikTextField name="name" label="Name" type="text" required />
        </Grid>
        <Grid item xs={4} md={3}>
          <FormikTextField name="visualId" label="ID (Auto)" variant="standard" type="text" />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField name="address.line1" label="Street & House Number" variant="standard" type="text" />
        </Grid>
        <Grid item xs={5} md={4}>
          <FormikTextField name="address.postalCode" label="ZIP" variant="standard" type="text" required />
        </Grid>
        <Grid item xs={7} md={8}>
          <FormikTextField name="address.city" label="City" variant="standard" type="text" required />
        </Grid>
        <Grid item xs={5} md={4}>
          <FormikTextField name="address.state" label="State" variant="standard" type="text" />
        </Grid>
        <Grid item xs={7} md={8}>
          <FormikSelect name="address.countryCode" label="Country" variant="standard" type="text" required>
            {countries.map(c => (
              <MenuItem key={c} value={c}>
                {countryNames[c]!}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>
        <Grid item xs={4}>
          <FormikTextField name="address.location.lat" label="Latitude" variant="standard" type="number" />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField name="address.location.long" label="Longitude" variant="standard" type="number" />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? 'none' : 'all',
          }}
        >
          <Button variant="outlined" size="small" onClick={() => geocodeAddress()}>
            Get Coordinates
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            fontSize: '0.8em',
            opacity: 0.5,
          }}
        >
          Must be in Decimal Degrees format (DDD.DDDDD)
        </Grid>
        <Grid item xs={6}>
          <Box m={2}></Box>
          <FormikSwitch size="small" name="underConstruction">
            under construction
          </FormikSwitch>
        </Grid>
        <Grid item xs={6}>
          <FormikTextField
            disabled={auth.user?.isSuperAdmin}
            name="iotRegistry"
            label="IoT Registry"
            variant="standard"
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <Box m={2}></Box>
          <FormikSwitch size="small" name="draft">
            draft
          </FormikSwitch>
        </Grid>
      </Grid>
    </>
  );
};

const Manager: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>Facility manager</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormikTextField name={`manager.name`} label="Name" variant="standard" type="text" />
      </Grid>

      <FieldArray name="manager.contactChannels">
        {fieldArrayProps => {
          const { push, remove, form } = fieldArrayProps;
          const values: Park = form.values;
          const add = () => push({ type: '', value: '' });

          return (
            <>
              {(values.manager?.contactChannels ?? []).map((_, index) => (
                <Grid
                  item
                  xs={12}
                  key={`manager.contactChannels[${index}]`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                  }}
                >
                  <Grid container spacing={1} sx={{ flex: 1 }}>
                    <Grid item xs={4} md={3}>
                      <FormikTextField
                        name={`manager.contactChannels[${index}].type`}
                        label="Type"
                        variant="standard"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <FormikTextField
                        name={`manager.contactChannels[${index}].value`}
                        label="Number/Value"
                        variant="standard"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                  <IconButton onClick={() => remove(index)}>
                    <TrashSimple size={24} />
                  </IconButton>
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Button variant="outlined" size="small" onClick={add}>
                  Add attribute
                </Button>
              </Grid>
            </>
          );
        }}
      </FieldArray>
    </Grid>
  );
};

interface UnitProps {
  unitState?: StorageUnit[];
  setUnitState: Dispatch<React.SetStateAction<StorageUnit[] | undefined>>;
}

const Units: React.FC<UnitProps> = ({ unitState, setUnitState }) => {
  const company = useContext(CompanyContext);
  const auth = React.useContext(AuthContext);

  const add = () => {
    const newUnit = {
      id: '',
      visualId: '',
      marketingLabel: '',
      category: {
        id: '',
        name: '',
        companyId: company?.id ?? '',
      },
      description: '',
      areaSqm: 0,
      depthMeters: undefined,
      heightMeters: undefined,
      widthMeters: undefined,
      keys: [],
      attributes: [],
      activeDeviceRatio: 0,
    } as StorageUnit;

    if (unitState) {
      setUnitState([...unitState, newUnit]);
    } else {
      setUnitState([newUnit]);
    }
  };

  const update = _.debounce((index: number, unit: any) => {
    const items = unitState ?? [unit];
    if (items) {
      items[index] = unit;
    }
    setUnitState(items);
  }, 250);

  const removeUnit = async (index: number) => {
    const items = unitState?.filter(x => x);
    items && delete items[index];
    setUnitState(items?.filter(x => x));
  };
  return (
    <Grid container spacing={3} className={'e2e-add-units'}>
      <Grid item xs={12}>
        <Typography>Storage Units</Typography>
      </Grid>
      <>
        {unitState &&
          unitState
            .sort((a, b) => (a.visualId < b.visualId ? -1 : 1))
            .map((unit, index) => (
              <Grid item xs={12} key={index}>
                <StorageUnitForm
                  company={company}
                  unit={unit}
                  hideUnitDeleteIcon={hideUnitDeleteIcon}
                  index={index}
                  remove={removeUnit}
                  update={update}
                />
                <Divider sx={{ marginTop: 3 }} />
              </Grid>
            ))}
        {checkPermissionsForResource(auth.user?.token!, 'storageUnit', 'create') && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button variant="outlined" size="small" onClick={add}>
              <span className="e2e-add-unit">Add Unit</span>
            </Button>
          </Grid>
        )}
      </>
    </Grid>
  );
};

export default ParkForm;
