import styled from '@emotion/styled';
import { checkPermissionsForResource } from '@ivy/auth';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { CaretRight, CircleNotch } from 'phosphor-react';
import * as React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Rotating } from '../../components/Rotating';
import SearchBar from '../../components/SearchBar';
import SiteHeader from '../../components/SiteHeader';
import StatusCell, { calculateStatus } from '../../components/StatusCell';
import { usePark } from '../../gateway/park/get';
import { StorageUnitListRentalPayloadRental } from '../../gateway/storageUnit/rentals';
import { TenantListPayloadTenant, useTenants } from '../../gateway/tenantGateway';
import { Park, StorageUnit } from '../../model';
import { colors } from '../../theming/colors';
import { Order, sortByEntry } from '../../util/util';
import ParkDetailSubHeader from './components/ParkDetailSubHeader';
import AuthContext from '../../context/AuthContext';

export interface UnitEntry {
  visualId: string;
  id: string;
  description: string;
  tenant: string;
  status?: any;
  thingsboardId?: string;
  activeDeviceRatio: number;
  rental?: StorageUnitListRentalPayloadRental;
}

const headCells: { id: keyof UnitEntry; label: string }[] = [
  {
    id: 'visualId',
    label: 'ID',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'tenant',
    label: 'Rental Owner',
  },
  {
    id: 'description',
    label: 'Description',
  },
];

const Cell = styled.div`
  display: flex;
  align-items: center;
`;

interface StorageUnitRowProps {
  unit: UnitEntry;
  park: Park;
  tenants: TenantListPayloadTenant[] | undefined;
}

const StorageUnitRow: React.FC<StorageUnitRowProps> = ({ unit, park, tenants }) => {
  const currentRental = unit.rental;
  const tenant = tenants?.find(tenant => tenant.id === currentRental?.tenantId);
  const navigate = useNavigate();

  const openUnit = (unit: UnitEntry) => {
    navigate(`unit/${unit.id}`);
  };

  return park === undefined ? (
    <Rotating>
      <CircleNotch size={24} color={colors.grey05} />
    </Rotating>
  ) : (
    <TableRow
      hover
      onClick={() => openUnit(unit)}
      key={unit.id}
      sx={{
        cursor: 'pointer',
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell>
        <Cell>
          <img src="/images/ivy-unit.svg" alt="logo" style={{ width: 24, height: 24 }} />
          <Box m={0.5} />
          <b>{unit.visualId}</b>
        </Cell>
      </TableCell>
      <TableCell>
        <StatusCell activeRatio={unit.activeDeviceRatio} type="unit" />
      </TableCell>
      <TableCell>
        {!tenants ? (
          <Rotating>
            <CircleNotch size={24} color={colors.grey05} />
          </Rotating>
        ) : tenant ? (
          `${tenant?.firstName} ${tenant?.lastName}`
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{unit.description}</TableCell>
      <TableCell align="right">
        <CaretRight size={20} weight="regular" />
      </TableCell>
    </TableRow>
  );
};

const ParkDetail: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const park = usePark(id!);
  const tenants = useTenants();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof UnitEntry>('visualId');
  const [sortedUnits, setSortedUnits] = React.useState<UnitEntry[]>([]);
  const auth = React.useContext(AuthContext);

  const units = park?.storageUnits;

  const onSortChange = (property: keyof UnitEntry) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const extractTenantNameFromId = React.useCallback(
    (tenantId: string): string => {
      const tenant = tenants?.find(tenant => tenant.id === tenantId);
      const tenantName = tenant ? `${tenant.firstName} ${tenant.lastName}` : '';

      return tenantName;
    },
    [tenants],
  );

  const extractUnitStatus = ({ activeRatio, type }) => {
    const { alert, degraded, healthy } = calculateStatus(activeRatio, type);
    const status = {
      alert,
      degraded,
      healthy,
      description: alert ? 'Alert' : degraded ? 'Degraded' : healthy ? 'OK' : '',
    };

    return status;
  };

  const parseUnits = React.useCallback(
    (units: StorageUnit[]): UnitEntry[] =>
      units?.map(unit => {
        return {
          visualId: unit.visualId,
          id: unit.id,
          description: unit.description,
          status: extractUnitStatus({ activeRatio: unit.activeDeviceRatio, type: 'unit' }).description,
          tenant: extractTenantNameFromId(unit.rental?.tenantId),
          thingsboardId: unit.thingsboardId,
          activeDeviceRatio: unit.activeDeviceRatio,
          rental: unit.rental,
        };
      }),
    [extractTenantNameFromId],
  );

  const parseUnitRows = React.useCallback(() => {
    const parsedUnits: UnitEntry[] = units ? parseUnits(units) : [];

    setSortedUnits(sortByEntry(parsedUnits, orderBy, order));
  }, [units, orderBy, order, parseUnits]);

  React.useEffect(() => {
    parseUnitRows();
  }, [parseUnitRows]);

  return park === undefined ? (
    <Backdrop sx={{ color: '#fff', background: 'transparent', width: 'calc(100vw - 240px)', left: 'auto' }} open={true}>
      <CircularProgress color="primary" />
    </Backdrop>
  ) : (
    <>
      <SiteHeader
        title={park.name ? `${park.name}${park.draft ? ' (draft)' : ''}` : ''}
        breadcrumps={[{ title: 'Parks', path: '/parks' }]}
      >
        {checkPermissionsForResource(auth.user?.token!, 'park', 'update') ? (
          <Button variant="outlined" size="medium" onClick={() => navigate(`/park/${id}/edit`)}>
            Edit Park
          </Button>
        ) : (
          ''
        )}
      </SiteHeader>
      <ParkDetailSubHeader {...{ park }}></ParkDetailSubHeader>
      <Box sx={{ px: 4, pb: 2 }}>
        {units ? (
          <SearchBar
            options={parseUnits(units)}
            inputAttribute={'visualId'}
            onInputChange={(_, newValue) => {
              if (newValue.trim() === '') {
                setSortedUnits(sortByEntry(parseUnits(units), orderBy, order));
                return;
              }
              const standardisedName = (name: string) => name.toLowerCase().trim();
              const results = units.filter(p => standardisedName(p.visualId).includes(standardisedName(newValue)));
              setSortedUnits(sortByEntry(parseUnits(results), orderBy, order));
            }}
          />
        ) : null}
        {units && (
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell key={headCell.id}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => onSortChange(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}

                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedUnits.map(unit => {
                return <StorageUnitRow key={unit.id} unit={unit} park={park} tenants={tenants}></StorageUnitRow>;
              })}
            </TableBody>
          </Table>
        )}
      </Box>

      <Outlet />
    </>
  );
};

export default ParkDetail;
