import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { colors } from '../../../theming/colors';
import { unlock } from '../../../gateway/storageUnitKeys/storageUnitKeysGateway';
import AuthContext from '../../../context/AuthContext';
import { CircleNotch } from 'phosphor-react';
import { Rotating } from '../../../components/Rotating';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import UnassignDeviceButton from '../../../components/UnassignDeviceButton';

const UnlockDialog: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const storageUnitId = params.storageUnitId;
  const parkId = params.parkId;
  const user = React.useContext(AuthContext).user!;
  const companyId = user.company;
  const { toaster } = useToaster();

  const onClose = () => {
    //remove component/id
    const parts = location.pathname.split('/');
    const prevPath = parts.slice(0, parts.length - 2).join('/');
    navigate(prevPath, {
      replace: true,
    });
  };

  const unlockStorageUnitAndCloseDialog = async () => {
    try {
      setLoading(true);
      await unlock(storageUnitId!, parkId!, companyId);
      toaster('Action Successful', 'Lock was unlocked', ToastMessageTypes.SUCCESS);
    } catch (e) {
      toaster('Action Failed', 'Lock could not be unlocked', ToastMessageTypes.ERROR);
    } finally {
      setLoading(false);
    }
    onClose();
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogContent>
        <Typography variant="h2">Unlock unit</Typography>
      </DialogContent>
      <DialogActions style={{ padding: 24 }}>
        <Button variant="outlined" size="medium" onClick={onClose}>
          Cancel
        </Button>
        <div style={{ flex: 1 }} />

        {user.isSuperAdmin && (
          <UnassignDeviceButton
            deviceId={params.deviceId!}
            storageUnitId={storageUnitId!}
            setParentLoadingState={setLoading}
            onComplete={onClose}
          />
        )}

        <Button disabled={loading} variant="contained" size="medium" onClick={unlockStorageUnitAndCloseDialog}>
          {loading ? (
            <Rotating>
              <CircleNotch size={24} color={colors.white} />
            </Rotating>
          ) : (
            <span className="e2e-unlock">Unlock</span>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnlockDialog;
