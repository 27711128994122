import { AuthenticatedUser, UserRole } from '@ivy/proto/dist/users/v2/auth';
import { decodeJwt } from 'jose';

export type CustomTokenData = {
  claims: {
    id: string;
    email: string;
    company_id: string;
    role: UserRole;
  };
};

/**
 * Decodes a firebase custom auth token into an authenticated user.
 * DOES NOT verify the token
 *
 * @param authToken - The encoded auth token.
 * @returns the decoded token.
 * @throws Error if the token is invalid.
 */
export const decodeAuthToken = (authToken: string, deps?: { decodeJwt: typeof decodeJwt }): AuthenticatedUser => {
  try {
    const decodedToken = deps?.decodeJwt<CustomTokenData>(authToken) ?? decodeJwt<CustomTokenData>(authToken);

    if (!decodedToken['claims']) throw new Error('missing claims');

    const { id, email, company_id, role } = decodedToken['claims'];

    if (!id) throw new Error('missing id');

    if (!email) throw new Error('missing email');

    if (!company_id) throw new Error('missing company_id');

    if (!role) throw new Error('missing role');

    return {
      id,
      email,
      companyId: company_id,
      role,
    };
  } catch (error) {
    throw new Error(`Invalid auth token: ${error}`);
  }
};
