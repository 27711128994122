import { UserRole } from '@ivy/proto/dist/users/v2/auth';
import { decodeAuthToken } from '../auth-tokens';

export type ResourceType = 'company' | 'park' | 'storageUnit' | 'device' | 'rental' | 'key' | 'user';
export type Action = 'create' | 'read' | 'update' | 'delete';
type ResourcePermissions = Record<ResourceType, Action[]>;

type UserResourcePermissions = Record<UserRole, ResourcePermissions>;

export const resourcePermissionModel: Partial<UserResourcePermissions> = {
  [UserRole.USER_ROLE_SUPER_ADMIN]: {
    company: ['create', 'read', 'update', 'delete'],
    park: ['create', 'read', 'update', 'delete'],
    storageUnit: ['create', 'read', 'update', 'delete'],
    device: ['create', 'read', 'update', 'delete'],
    rental: ['create', 'read', 'update', 'delete'],
    key: ['create', 'read', 'update', 'delete'],
    user: ['create', 'read', 'update', 'delete'],
  },
  [UserRole.USER_ROLE_ADMIN]: {
    company: ['create', 'read', 'update'],
    park: ['create', 'read', 'update'],
    storageUnit: ['create', 'read', 'update', 'delete'],
    device: ['read'],
    rental: ['create', 'read', 'update'],
    key: ['create', 'read', 'update'],
    user: ['create', 'read', 'update', 'delete'],
  },
  [UserRole.USER_ROLE_SERVICE_TEAM]: {
    company: ['read'],
    park: ['read'],
    storageUnit: ['read', 'update'],
    device: ['read'],
    rental: ['create', 'read'],
    key: ['create', 'read', 'update'],
    user: ['create', 'read'],
  },
  [UserRole.USER_ROLE_FACILITY_MANAGER]: {
    company: ['read'],
    park: ['read'],
    storageUnit: ['read'],
    device: ['read'],
    rental: ['read'],
    key: ['create', 'read'],
    user: ['read'],
  },
  [UserRole.USER_ROLE_FACILITY_MANAGER_EXTERNAL]: {
    company: ['read'],
    park: ['read'],
    storageUnit: ['read'],
    device: ['read'],
    rental: ['read'],
    key: ['read'],
    user: [],
  },
  [UserRole.USER_ROLE_TENANT]: {
    company: ['read'],
    park: ['read'],
    storageUnit: ['read'],
    device: ['read'],
    rental: ['read'],
    key: ['read'],
    user: [],
  },
};

export const authorizeRoleForResource = (
  authToken: string,
  resourceType: ResourceType,
  action: Action,
  deps?: { decodeAuthToken: typeof decodeAuthToken },
) => {
  const { role } = deps?.decodeAuthToken(authToken) ?? decodeAuthToken(authToken);

  // Get the permissions for the specific role
  const rolePermissions = resourcePermissionModel[role];

  // If the user role is not recognized, default to false
  if (!rolePermissions) {
    return false;
  }

  // Get the role's permissions for the specific resource type
  const resourcePermissions = rolePermissions[resourceType];

  // At runtime someone could pass in the wrong resource type
  if (!resourcePermissions) {
    return false;
  }

  return resourcePermissions.includes(action);
};

/**
 * Helper function to get a list of roles which are allowed to perform an action on a resource type.
 * @param resourceType
 * @returns
 */
export const getAuthorizedRolesForResource = (resourceType: ResourceType, action: Action) => {
  return Object.keys(resourcePermissionModel).filter(role =>
    resourcePermissionModel[role as UserRole]?.[resourceType]?.includes(action),
  );
};

/**
 * Determines if a user has permission to perform an action on a resource type.
 * We currently use a simple RBAC model for authorization.
 *
 * @param authToken - The auth token of the user.
 * @param resourceType - The type of resource to authorize.
 * @param action - The action the user is trying to perform.
 */
export const checkPermissionsForResource = (authToken: string, resourceType: ResourceType, action: Action) => {
  return authorizeRoleForResource(authToken, resourceType, action);
};
